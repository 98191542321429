<template>
  <div class="col-lg-6 pt-lg-5 ps-lg-5 pe-lg-2 pb-0">
    <div class="row m-0 p-md-5 py-5">
      <div class="col-12 p-0" v-if="selectedBranch">
        <div class="row m-0">
          <div class="col-12 p-0 text-center gold_heading mb-2">
            <div class="row m-0 justify-content-center align-items-center" ref="theoffice">
              <div class="col-auto px-0">
                <IconLocation />
              </div>
              <div class="col-auto">
                {{selectedBranch.name}}
              </div>
            </div>
          </div>
          <div class="col-12 p-0 my-4 text-center contact_heading">
            {{selectedBranch.name}} <span v-if="selectedBranch.name === 'South Africa'">Head</span> Office
            <div class="row m-0 justify-content-center">
              <div class="col-4 col-md-6 pt-4 text-center">
                <div class="contact_heading_underline"></div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 text-center mb-2">
            <div class="row m-0 justify-content-center align-items-center">
              <div class="col-auto px-0">
                <IconLocation color="gold-text" size="size16" />
              </div>
              <div class="col lh-sm">
                {{selectedBranch.physicalAddress}}
              </div>
            </div>
          </div>
          <div class="col-12 p-0 text-center mb-2">
            <div class="row m-0 justify-content-center align-items-center">
              <div class="col-auto px-0 pt-1">
                <IconPhone color="gold-text" size="size16" />
              </div>
              <div class="col-auto">
                {{selectedBranch.telephone}}
              </div>
            </div>
          </div>
          <div class="col-12 p-0 text-center mb-2">
            <div class="row m-0 justify-content-center align-items-center">
              <div class="col-auto px-0 pt-1">
                <IconMail color="gold-text" size="size16" />
              </div>
              <div class="col-auto">
                {{selectedBranch.email}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocialMediaLinks />
    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SocialMediaLinks: defineAsyncComponent(() => import('../components/SocialMediaLinks.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconMail: defineAsyncComponent(() => import('@/components/icons/IconMail.vue')),
    IconLocation: defineAsyncComponent(() => import('@/components/icons/IconLocation.vue'))
  },
  name: 'SelectedBranch',
  props: ['selectedBranch', 'branchUpdated'],
  watch: {
    branchUpdated () {
      if (this.branchUpdated) {
        this.scrollToBranch()
      }
    }
  },
  methods: {
    scrollToBranch () {
      if (this.branchUpdated) {
        var element = this.$refs.theoffice
        var viewportOffset = element.getBoundingClientRect()
        var top = viewportOffset.top
        window.scrollBy(0, top)
      }
    }
  }
}
</script>

<style scoped>

.gold_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
  letter-spacing: 2.4px;
  color: var(--gold-color);
  font-size: 1rem;
  text-transform: uppercase;
}
.contact_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  color: #fff;
  line-height: 1;
}
.contact_heading_underline {
  border-bottom: 2px solid var(--gold-color);
}

@media (min-width: 992px) {
  .contact_heading {
    font-size: 3.3rem;
  }
}
</style>
